import { createApp } from "vue";
import App from "@/App.vue";
import { createPinia } from 'pinia'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/sass/items.scss"
import "../src/assets/sass/variables.scss"
import router from "@/router";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'boxicons/css/boxicons.css';

const pinia = createPinia();

const app = createApp(App);
app.use(ElementPlus);
app.use(router).mount("#app");
app.use(pinia);
