import axios from "axios";

class ApiService {
    public static customAxios()
    {
        if (process.env.VUE_APP_BACKEND_PORT != '') {
            return axios.create({baseURL: process.env.VUE_APP_BACKEND_URL+':'+process.env.VUE_APP_BACKEND_PORT});
        }
        return axios.create({baseURL: process.env.VUE_APP_BACKEND_URL});
    }
}

export default ApiService;