import ApiService from '../../services/ApiService';

const checkRoomExist = async (id: any) => {
    await ApiService.customAxios().post('/room/check', {'room':id})
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw new Error(error);
        });
}

export default {
    checkRoomExist
}